import React from 'react';
import { graphql, StaticQuery, Link } from 'gatsby';
import styled from 'styled-components';

const CTA = styled(Link)`
  &&& {
    background: #CB453A;
    color: white;

    &:hover {
      background: #4fb5b2;
    }
  }
`;

const MenuMobile = (props) => {
  const { menuLinks } = props.data.site.siteMetadata;
  const activeLinks = menuLinks.filter(link => link.active);
  return (
    <div
      id="main-menu-mobile"
      className={`main-menu-mobile ${props.active ? 'open' : ''}`}
    >
      <ul>
        {activeLinks.map(link => (
          <li key={link.name}>
            <Link
              partiallyActive
              to={link.link}
              activeStyle={activeLink}
            >
              {link.name}
            </Link>
          </li>
        ))}
        <li>
          <CTA
            to="/application"
            activeStyle={activeLink}
          >
            APPLY
          </CTA>
        </li>
      </ul>
    </div>
  );
};

const activeLink = {
  textDecoration: 'underline',
};

export default props => (
  <StaticQuery
    query={graphql`
      query MenuMobileQuery {
        site {
          siteMetadata {
            menuLinks {
              name
              link
              active
            }
          }
        }
      }
    `}
    render={data => <MenuMobile active={props.active} data={data} />}
  />
);
