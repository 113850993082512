const theme = {
  colors: {
    background: '#FFFFFF',
    backgroundDark: '#f0e6f6',

    primary: '#4fb5b2',
    primaryLight: '#a3d1d0',
    primaryDark: '#3f6e6c',

    secondary: '#ff4081',
    secondaryLight: '#ff79b0',
    secondaryDark: '#c60055',
  },
};

export default theme;
