import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Link from './Link';

const CTA = styled(Link)`
  &&& {
    background: #CB453A;
    color: white;

    &:hover {
      background: #4fb5b2;
    }
  }
`;

const activeLink = {
  color: 'white',
  background: '#4fb5b2',
};

const Menu = () => {
  const { site } = useStaticQuery(query);
  const activeLinks = site.siteMetadata.menuLinks.filter(link => link.active);
  return (
    <div
      id="main-menu"
      className="main-menu"
    >
      <ul>
        {activeLinks.map(item => (
            <li key={item.name}>
              <Link
                partiallyActive
                to={item.link}
                activeStyle={activeLink}
              >
                {item.name}
              </Link>
            </li>
        ))}
        <li>
          <CTA
            to="https://www.f6s.com/mbanqlabsbatch3/apply"
            activeStyle={activeLink}
          >
            APPLY
          </CTA>
        </li>
      </ul>
    </div>
  );
};

const query = graphql`
  query {
    site {
      siteMetadata {
        menuLinks {
          name
          link
          active
        }
      }
    }
  }
`;

export default Menu;
