import React from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import config from 'react-reveal/globals';
import SEO from '../components/SEO';
import Header from '../components/Header';
import Footer from '../components/Footer';
import theme from './theme';
import '../scss/style.scss';

config({ ssrFadeout: true });
if (typeof window !== 'undefined') {
  // Make scroll behavior of internal links smooth
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
    easing: 'easeInOutCubic',
  });
}

const Layout = ({ children, bodyClass }) => (
  <React.Fragment>
    <SEO />
    <ThemeProvider theme={ theme }>
      <div className={`page${bodyClass ? ` ${bodyClass}` : ''}`}>
        <div id="wrapper" className="wrapper">
          <Header />
          {children}
        </div>
        <Footer />
      </div>
    </ThemeProvider>
  </React.Fragment>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  bodyClass: PropTypes.string.isRequired,
};

export default Layout;
