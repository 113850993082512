import React from 'react';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import { Link as RebassLink } from 'rebass';

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink

export const StyledRebassLink = styled(RebassLink)`
  color: ${props => props.theme.colors.primary};
`;

const Link = ({
  children, to, activeClassName, partiallyActive, ...other
}) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to);

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    );
  }
  return (
    <StyledRebassLink
      href={to}
      target="_blank"
      {...other}
    >
      {children}
    </StyledRebassLink>
  );
};

export default Link;
