import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import SocialLink from './SocialLink';
import Link from './Link';

const Footer = () => {
  const data = useStaticQuery(query);
  const {
    title, address, email, socialLinks,
  } = data.site.siteMetadata;

  return (
    <div className="footer-strip">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="footer align-items-start" >
              <div className="col-12 col-md-4">
                <h3 className="footer-title">
                  {title}
                </h3>
                <p>
                  {address}
                </p>
                <a href={`mailto:daniel.ong@mbanq.com`}>daniel.ong@mbanq.com</a>
                <ul>
                  {socialLinks.map(social => (
                    <li key={social.url} className="social">
                      <SocialLink
                        icon={social.network}
                        url={social.url}
                      />
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-12 col-md-3">
                <h3 className="footer-title">
                  For Startups
                </h3>
                <ul>
                  <li><Link to="/mentors">Mentors</Link></li>
                  <li><Link to="/program">Program</Link></li>
                  <li><Link to="https://www.f6s.com/mbanqlabsbatch3/apply">Join</Link></li>
                </ul>
              </div>
              <div className="col-12 col-md-3">
                <h3 className="footer-title">
                  For Partners
                </h3>
                <ul>
                  <li><Link to="/partners">Partners</Link></li>
                  <li><Link to="mailto:daniel.ong@mbanq.io?subject=Partner Application">Join as partner</Link></li>
                  <li><Link to="mailto:daniel.ong@mbanq.io?subject=Sponsor Application">Join as sponsor</Link></li>
                </ul>
              </div>
              <div className="col-12 col-md-3">
                <h3 className="footer-title">
                  Disclaimer
                </h3>
                <p style={{ fontSize: "12px" }}>
                  All third-party names and logos are trademarks of their respective owners. These owners are not affiliated with Mbanq or Mbanq Labs and have not sponsored or endorsed Mbanq or Mbanq Labs' products or services.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const query = graphql`
  query {
    site {
      siteMetadata {
        title
        address
        email
        socialLinks {
          network
          url
        }
      }
    }
  }
`;

export default Footer;
