import React from 'react';
import FontAwesome from 'react-fontawesome';
import { Link } from 'rebass';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import 'font-awesome/css/font-awesome.min.css';

const IconLink = styled(Link)`
  transition: color 0.5s;
  color: ${props => props.theme.colors.primary};

  &:hover {
    color: ${props => props.theme.colors.primaryLight};
  }
`;

const SocialLink = ({ icon, name, url }) => {
  let sLink = null;

  if (name) {
    sLink = <Tooltip title={name} position="right" trigger="mouseenter">
              <IconLink href={url} target="_blank">
                <FontAwesome name={icon} />
              </IconLink>
            </Tooltip>;
  } else {
    sLink = <IconLink href={url} target="_blank">
              <FontAwesome name={icon} />
            </IconLink>;
  }
  return (
    sLink
  );
};

SocialLink.propTypes = {
  icon: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  name: PropTypes.string,
};

export default SocialLink;
